// import jQuery from 'jquery';
// import { Tooltip, Toast, Popover } from 'bootstrap';
// import * as mdb from 'mdb-ui-kit'; // lib

// import { Input } from 'mdb-ui-kit'; // module

// <script src="{% static 'js/display_modals.js' %}" charset="UTF-8"></script>
// <script src="{% static 'js/nav_bar.js' %}" charset="UTF-8"></script>

// import { paintNavItem } from '../scripts/nav_bar';
// import { paintSideNavButton } from '../scripts/back_office/sidenav';
//import { removeLoading } from "../scripts/display_modals";
import { Tooltip, Toast, Popover, Collapse, Dropdown, Modal } from "bootstrap";

window.addEventListener("load", (e) => {
  // paintNavItem();
  // paintSideNavButton();
});

// 別ウインドウを開くイベントをキャッチし、removeLoadingを実行
//window.addEventListener("focusout", (e) => {
//  removeLoading();
//});
// new WOW().init();
